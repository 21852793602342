.siteDetails[data-pint-1321="setToNone"] {
	padding: 20px;
	width: 100%;
	float: left;
	background: #FFF;
  margin-bottom: 25px;
	.siteTitle {
		margin: 0 0 20px 0;
		width: 100%;
		float: left;

		h1 {
			font-size: 52px;
		}

		a {
			font-size: 16px;
			color: #303030;

			&:hover {
				color: #32b44a;
				text-decoration: underline;
			}
		}
	}

	.siteImg {
		margin: 0 0 20px 0;
		width: 100%;
		float: left;
		border: 0 solid #d0d0d0;

		a {
			width: 100%;
			float: left;

			img {
				width: 100%;
				height: 100%;
				float: left;
			}
		}
	}

	.siteText {
		margin: 20px 0 0 0;
		width: 100%;
		float: left;

		h2 {
			text-align: center;
		}

		p {
			margin: 10px 0 10px 0;
			font-family: 'arial';
			font-size: 11pt;
			color: #606060;
			line-height: 24px;
			float: left;
		}

		a {
			margin-bottom: 20px;
			padding: 10px;
			width: 100%;
			font-size: 24px;
			color: #FFF;
			text-align: center;
			float: left;
			background: #32b44a;
			border-radius: 6px;
			border: 1px solid #3e3e3e;

			&:hover {
				background: #3e3e3e;
			}
		}
	}

	.siteLeft {
		width: 100%;
		margin-bottom: 10px;

		.siteRating {
			margin: auto;
			width: 100%;
			text-align: center;
		
			.trioScores{
				display: flex;
				justify-content: center;

				.singleScore{
					margin: 10px;
					font-size: 18px;
					font-weight: 700;
					line-height: 150%;
					.score{
						.green{
							color:#3FD245;
						}
						.red{
							color: #E03838;
						}
						.orange{
							color: #E06938;
						}
						.black{
							color: #090909;
						}
					}
				}
			}
			.rateTitle strong {
				font-size: 24px;
				color: #090909;
				line-height: 36px;
				font-weight: 700;
			}

			.rateStars {
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 5px 0px;

				div {
					position: relative;
					font-size: 20px;
					color: #FFF;
					cursor: pointer;
					line-height: 20px;

					&:before {
						position: absolute;
						left: 0;
						content: '\2605';
						color: #ccc
					}

					&.full:before,
					&.half:before {
						color: gold
					}

					&.half:after {
						position: absolute;
						left: 50%;
						width: 50%;
						content: '\2605';
						color: #ccc;
						overflow: hidden;
						direction: rtl;
					}
				}
			}
		}

		.siteLink {
			padding: 20px 40px;
			color: #FFF;
			text-align: center;
			background-color: #e06939;
			border-radius: 6px;
			margin-bottom: 10px;
			margin: auto;
			font-size: 20px;
			font-weight: 800;
			line-height: 150%;
			text-transform: capitalize;
			white-space: nowrap;

			&:hover {
				background: #3e3e3e;
			}			
		}
	}

	.siteLinkReview {
		padding: 10px;
		width: 100%;
		font-size: 24px;
		color: #FFF;
		text-align: center;
		float: left;
		background: #32b44a;
		border-radius: 6px;
		border: 1px solid #3e3e3e;

		&:hover {
			background: #3e3e3e;
		}
	}

	.siteRight {
		width: 100%;
		padding-top: 10px;
		.siteBox {
			margin: 0 0 20px 0;
			width: 100%;
			float: left;
			
			&:last-child {
				margin: 0;
			}

			&.pros strong {
				color: #7ae691;	
				background: url('https://assets.myfavsexcams.xxx/images/pros.svg') no-repeat 10px 6px;
				background-size: 25px 25px;
				border-bottom: 1px solid #3FD245;
			}
	
			&.cons strong {
				color: #ec5151;
				background: url('https://assets.myfavsexcams.xxx/images/cons.svg') no-repeat 10px 8px;
				background-size: 25px 25px;
				border-bottom: 1px solid #E03838;

			}

			strong {
				padding: 10px 0 10px 45px;
				width: 100%;
				font-weight: bold;
				color: #32b44a;
				float: left;
			}

			p {
				margin: 10px;
				font-size: 11px;
				line-height: 24px;
				color: #606060;
				float: left;
			}

			ul {
				margin: 10px 10px 10px 40px;
				float: left;
				list-style-type: disc;
				padding-inline-start: 0px;

				li {
					padding: 0;
					margin: 0 0 5px 0;
					font-size: 11pt;
					line-height: 15px;
					color: #606060;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}

	.siteDescription {
		margin-bottom: 20px;
		width: 100%;
		float: left;
		strong {
			padding: 10px 0 10px 45px;
			width: 100%;
			font-weight: bold;
			color: #090909;
			float: left;
			background: url('https://assets.myfavsexcams.xxx/images/black-check-mark.svg') no-repeat 10px 8px;
			background-size: 25px 25px;
			border-bottom: 1px solid #090909;
		}

		p {
			margin: 10px;
			font-size: 15px;
			line-height: 19px;
			color: #606060;
			float: left;
			text-align: justify;
		}
	}

	@media (min-width: 800px) {
		.siteText a {
			margin-bottom: unset;
		}

		.siteLeft {
			padding: 0 0 0 10px;
			width: 45%;
			float: left;
			margin: 10px;
		}

		.siteRight {
			padding: 0 0 0 10px;
			width: 45%;
			float: right;
			margin: 10px;

		}		
	}
}


.siteRating {
	margin: auto;
	width: 100%;
	text-align: center;
	p{
		margin: 5px;
	}

	.trioScores{
		display: flex;
		justify-content: center;

		.singleScore{
			margin: 7px 7px 0px 7px;
			font-size: 14px;
			font-weight: 700;
			line-height:14px;
			.score{
				.green{
					color:#3FD245;
				}
				.red{
					color: #E03838;
				}
				.orange{
					color: #E06938;
				}
				.black{
					color: #090909;
				}
			}
		}
	}
	.rateTitle strong {
		font-size: 16px;
		color: #090909;
		line-height: 24px;
		font-weight: 700;
	}

	.rateStars {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 5px 0px;

		div {
			position: relative;
			font-size: 20px;
			color: #FFF;
			cursor: pointer;
			line-height: 20px;

			&:before {
				position: absolute;
				left: 0;
				content: '\2605';
				color: #ccc
			}

			&.full:before,
			&.half:before {
				color: gold
			}

			&.half:after {
				position: absolute;
				left: 50%;
				width: 50%;
				content: '\2605';
				color: #ccc;
				overflow: hidden;
				direction: rtl;
			}
		}
	}
}


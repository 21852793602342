.topSitesWrapper{
    .categoryBanner{
        background: #000000;
        color:#F8F8F8;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px ;
        padding: 9px;
        margin: 10px;
        @media (max-width: 850px){
        margin: unset;
        }
      
    }
    .topThreeSites{
        display:flex;
        flex-direction: row;
          @media (max-width: 850px){
            flex-wrap: wrap;
            justify-content: center;

          }
          @media (max-width: 550px) {
            flex-direction: column; 
            align-items: center;
          }
        
        .topSite{
            margin:12px;
            width:268px ;
            display:flex;
            flex-direction: column; 
            @media (max-width: 850px){
                width:318px ;
  
            }
            @media (max-width: 550px) {
                width:328px ;
            }
            .topSiteTitle{
                color: #090909;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                height: 60px;
                overflow: hidden;
                display: flex;
                align-items: center;
                margin-bottom: 12px;


            }
            .topSiteCard{
                background-color: #F8F8F8;
                display: flex;
                flex-direction: column;
                .image{
          
                    a{
                        div{
                            display: block !important;
                        }
                    }
                    .siteImg{
                        width: 100%;
                        display: block;
                        
                    }
                }
                .cardContent{
                    padding: 12px;
                    display: flex;
                    flex-direction: column;
                    .rating{
                        width: 100%;
                        height: 94px;
                        
    
                    }
                    .pros{
                        font-size: 14px;
                        font-weight: 400;
                        line-height:21px;
                        height:140px ;
                        overflow: hidden;
                        margin: 12px 0;
                        ul{
                            padding: unset;
                            margin: unset;
                            padding-left: 20px;
                            li{
                                &::marker {
                                    color: #090909;
                                }
                            }
                          
                        }
                    }
                    .spacer{
                        flex-grow: 1;
                        overflow: hidden;


                    }
                    .visitButton{
                        color: #FFF;
                        background-color: #e06939;
                        border-radius: 6px;
                        display: flex;
                        justify-content: center;
                        padding: 10px 15px;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: center;
                        text-decoration: none;
                        margin-bottom:10px;
    
                    }
                    .readButton{
                        color: #090909;
                        border:1px solid #090909;
                        border-radius: 6px;
                        display: flex;
                        justify-content: center;
                        padding: 10px 15px;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: center;
                        text-decoration: none;
    
                    }
    

                }
                
            }

        }
    }
    

}

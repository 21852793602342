@import '../variables';

.container {
    margin: 5px 0px;

    .homeBubble, .bubble {
        padding: 10px 15px;
        line-height: 22px;

        background: #FFF;
        border: #d0d0d0;
        border-radius: 20px 20px;

        .content {
            color: #606060;
        }
    }

    .homeBubble {
        .content p {
            margin-bottom: 5px;    
            a {
                color: #0000EE;
            }
        }
        .content h1{
            font-size: 24px;
            line-height: 24px;
            text-align: center;
        }
    }

    a {
        color: #e06939;
    }

    @media (min-width: 550px) {
        margin: 10px 0px;
        min-height: auto !important;

        .homeBubble {
            .content p, .links {
                display: block;
            }
            .content h1{
                text-align: left;
            }
        }

        .bubble {
            display: block
        }
    }

    @media (min-width: 990px) {
        .bubble, .homeBubble {
            width: 75%;
        }
    }
}